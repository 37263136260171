<template>
  <div class="content">
    <div v-for="(item,index) in banklist" :key="index" class="bankContentWrap">
      <div class="hotlistContent">
        <div class="bankContent" @click="Business(item)">
          <div class="bankImg">
            <img :src="item.creditSingleUrl" alt="">
          </div>
        </div>
        <div class="bankContentSpecific">
          <div @click="Business(item)">
            <p class="bankName">{{ item.merCardName }}</p>
            <div v-for="(items, tIndex) in item.labelTitle" :key="tIndex" class="bankDescription">
              <img
                src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-06/18/yuelvhuisAcbKDBpcV1607249442.png"
                alt=""
              >
              <p>{{ items }}</p>
            </div>
          </div>
          <div class="bankBusiness">
            <div class="bankBusinessBtn" @click="Business(item)">{{ item.cardAmount }}</div>
            <div class="bankBusinessBtn" @click="share(item)">{{ item.shareAmount }}</div>
          </div>
          <div style="clear: both" />
        </div>
      </div>
      <div v-if="index!=2" class="van-hairline--bottom" />
    </div>
    <div class="noMoreData">没有更多了～</div>
  </div>
</template>

<script>
import {
  getMoreBankList
} from '@/services/comeOn.js'
import { Toast } from 'vant'

export default {
  name: 'MoreBank',
  data() {
    return {
      banklist: [], // 银行卡列表
      DescriptionList: []// 银行卡描述
    }
  },
  created() {

  },
  mounted() {
    // 列表
    this.getBankList()
  },
  methods: {
    getBankList() {
      getMoreBankList().then(res => {
        Toast.loading({
          message: '加载中...',
          forbidClick: true
        })
        if (Number(res.code) === 200) {
          this.banklist = res.data
        } else if (Number(res.code) === 400) {
          this.$router.push({
            path: '/Credit/creditcardapply'
          })
        }
      })
    },
    //    办卡
    Business(item) {
      this.$router.push({
        path: '/Creditcardinfo',
        query: {
          leadPageUrl: item.leadPageUrl,
          bankCardId: item.merCardId,
          bankId: item.bankId,
          gateWayId: item.gatewayId,
          typeId: item.typeId,
          supportCity: item.supportCity, // 支持城市
          settlementRules: item.settlementRules, // 奖励规则
          cardAmount: item.cardAmount, // 办卡返多少
          shareAmount: item.shareAmount// 分享返多少
        }
      })
    },
    // 分享
    share(item) {
      this.$router.push({
        path: '/infoposter',
        query: {
          mid: window.localStorage.getItem('uid'),
          bankId: item.bankId,
          typeId: item.typeId,
          title: item.merCardName,
          merCardId: this.merCardId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.content {
  position: absolute;
  background-color: #F8F8F8;
  padding-bottom: 20px;

  .bankContentWrap {
    width: 100%;
    background-color: #fff;
    padding: 22px 0 0 15px;

    .hotlistContent {
      width: 100%;
      margin-bottom: 17px;
      display: flex;

      .bankContent {
        display: flex;

        .bankImg {
          width: 107px;
          margin-right: 13px;

          img {
            width: 100%;
            height: 67px;
          }
        }
      }

      .bankContentSpecific {
        text-align: left;

        .bankName {
          color: #242424;
          font-size: 17px;
          font-weight: 700;
        }

        .bankDescription {
          display: flex;
          margin-top: 13px;

          img {
            width: 12px;
            height: 12px;
          }

          p:nth-child(2) {
            color: #666;
            font-size: 13px;
            margin-left: 5px;
          }
        }

        .bankBusiness {
          float: left;
          margin-bottom: 17px;

          .bankBusinessBtn {
            float: left;
            height: 25px;
            padding-left: 10px;
            padding-right: 10px;
            line-height: 27px;
            text-align: center;
            color: #fff;
            font-size: 13px;
            background-color: #DCA57B;
            border-radius: 13px;
            margin-top: 13px;
          }

          div:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .bankBusiness {
    float: left;
    margin-bottom: 17px;
    margin-left: 110px;

    .bankBusinessBtn {
      height: 25px;
      line-height: 27px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      color: #fff;
      font-size: 13px;
      background-color: #DCA57B;
      border-radius: 13px;
      margin-top: 13px;
      float: left;
    }

    div:nth-child(1) {
      margin-right: 10px;
    }
  }

  .noMoreData {
    margin-top: 20px;
    font-size: 12px;
    color: #666;
  }
}
</style>
